@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

body {
  font-family: "Roboto", "Poppins", sans-serif;

  margin: 0;
  padding: 0;
}
.Faq-genFam {
  margin-top: 100px;
  margin-left: 50px;
  margin-right: 50px;
  padding-bottom: 100px;
  max-width: 1500px;
  /* margin-left: auto;
  margin-right: auto; */
}
.Faq-accordion-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;

  color: #333333;
  margin-bottom: 20px;
}

.Faq-headingFlex {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  width: 100%;
  height: 58px;

  /* White */

  background: #ffffff;
  cursor: pointer;
  /* Label */

  border-bottom: 1px solid #e9e9e9;
  transition: 0.5s ease-in-out 0s;
}

.Faq-headingFlex:hover {
  background: #e9e9e9;
}
.Faq-titleArrow {
  transition: 0.5s ease-in-out 0s;
  transform: rotate(360deg);
  margin-right: 30px;
  margin-left: 20px;
}

.Faq-titleArrowRota {
  transition: 0.5s ease-in-out 0s;
  transform: rotate(180deg);
  margin-right: 30px;
  margin-left: 20px;
}
.Faq-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;

  color: #333333;
}

.Faq-content {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  /* overflow: hidden; */
  display: none;
  font-size: 16px;
  line-height: 24px;

  color: #333333;
  background: #ffffff;
  width: fit-content;
  /* transition: all 1.5s cubic-bezier(2, 2, 2, 2); */
  /* transition: 0.5s ease-in-out 0s; */
}

.Faq-content.show {
  display: block;
  height: auto;
  max-height: 9999px;
  margin-right: 20px;
  margin-left: 20px;
  /* transition: 0.5s ease-in-out 0s; */
  /* transition: all 1.5s cubic-bezier(2, 2, 2, 2); */
}

@media screen and (min-width: 1809px) {
  .Faq-genFam {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 1600px) {
}

@media screen and (max-width: 992px) {
  body {
    background: #ffffff;
    overflow-x: hidden;
    /* width: 100%; */
  }

  .Faq-genFam {
    padding-bottom: 335px;
  }

  .Faq-title {
    font-size: 13px;
    width: 80%;
    word-wrap: break-word;
  }

  .Faq-accordion-heading {
    font-size: 20px;
    text-align: center;
  }
}

/* @media screen and (max-width: 420px) {
  .homePage-contFamLeftF {
    font-weight: 600;
    font-size: 37px;
    margin-left: auto;
    margin-right: auto;
  }
} */
