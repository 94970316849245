@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  font-family: "Roboto", sans-serif;

  background: #fcfcfc;
  margin: 0;
  padding: 0;
}

.authorizerNavGenFams {
  position: fixed;
  width: 100%;
  z-index: 1000000000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
}

.authnavbar-headingLogo {
  cursor: pointer;
}

.authnavbar-headingLogo img {
  max-width: 124.84px;
  max-height: 29.59px;
}

@media screen and (max-width: 992px) {
  .authnavbar-headingLogo img {
    width: 82.89px;
    height: 19.65px;
  }
}
