@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

body {
  font-family: "Roboto", "Poppins", sans-serif;

  margin: 0;
  padding: 0;
}

.teamCompFour-famGenhh {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.teamCompFiveUpImg {
  margin-right: 15px;
}

.teamCompFour-famGenhh img {
  max-width: 514px;
  min-height: 425px;
}

.teamCompFour-flexleftFour {
  width: 50%;
  /* margin-left: 20px;
  margin-right: 10px; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.teamCompFour-flexleftFour img {
  width: 468.41px;
  height: 459px;
}

.teamCompFour-flexright {
  width: 50%;
  margin-left: 20px;
  display: flex;
  /* justify-content: center; */
  align-items: flex-start;
  flex-direction: column;
}

.teamCompFour-flexRight1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 46px;
  line-height: 72px;

  letter-spacing: -0.01em;
  max-width: 500px;
  word-wrap: break-word;

  /* Text color */

  color: #333333;
}

.teamCompFour-flexRight2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;

  letter-spacing: 0.015em;
  max-width: 450px;
  word-wrap: break-word;
  margin-top: 20px;

  /* Text color */

  color: #333333;

  opacity: 0.7;
}

@media screen and (max-width: 992px) {
  body {
    background: #ffffff;
    overflow-x: hidden;
    /* width: 100%; */
  }

  .teamCompFour-famGenhh {
    /* display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px; */

    flex-direction: column;
  }
  .teamCompFiveUpImg {
    margin-right: 0px;
  }
  .teamCompFour-famGenhh img {
    max-width: 343px;
    min-height: 284px;
    margin-top: 70px;
  }
  .teamCompFour-flexleftFour {
    width: 100%;
    /* margin-left: 20px;
  margin-right: 10px; */
    display: flex;
    justify-content: center;
    /* align-items: center; */
  }

  .teamCompFour-flexright {
    width: 100%;
    margin-left: 0px;
    /* display: flex; */
    /* justify-content: center; */
    align-items: center;
    /* flex-direction: column; */
  }

  .teamCompFour-flexRight1 {
    font-size: 32px;
    line-height: 52px;
    text-align: center;

    /* letter-spacing: -0.01em; */
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;
  }

  .teamCompFour-flexRight2 {
    font-size: 17px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.015em;
    /* max-width: 550px; */
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;

    /* word-wrap: break-word;
  margin-top: 20px; */

    /* Text color */

    color: #333333;

    opacity: 0.7;
  }

  .teamCompFour-flexleftFour img {
    margin-top: 70px;
    margin-bottom: 30px;
    width: 343px;
    height: 284px;
  }
}
