@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

body {
  font-family: "Roboto", "Poppins", sans-serif;

  background: #fcfcfc;
  margin: 0;
  padding: 0;
}

.homepageCompTwo-flexd {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding-top: 100px;
  padding-left: 20px;
  padding-right: 20px;
}

.homepageCompTwo-flexLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin-right: 100px;
  width: 50%;
}

.homepageCompTwo-LeftOnete {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 72px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: -0.01em;

  /* Text color */

  color: #333333;
}

.homepageCompTwo-LeftOneFuText {
  margin-top: 5px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  max-width: 350px;
  word-wrap: break-word;
  /* or 142% */

  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.015em;

  /* Text color */

  color: #333333;

  opacity: 0.7;
}

.homepageCompTwo-LeftOneImg {
  margin-right: -50px;
  margin-top: -20px;
}

.homepageCompTwo-LeftOneImg img {
  width: 272.35px;
  height: 152.91px;
}

.homepageCompTwo-LeftOneBtn {
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 14px 53px; */

  /* max-width: 340px;
  min-height: 70px; */

  padding: 20px 45px;
  /* width: 328px;
  height: 104px; */
  background: #36a6a4;
  border-radius: 3px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;

  color: #ffffff;
  transition: 0.5s ease-in-out 0s;
}

.homepageCompTwo-LeftOneBtn:hover {
  background: #000000;
  color: #ffffff;
}
.homepageCompTwo-RightImg {
  width: 50%;
}

.homepageCompTwo-RightImg img {
  max-width: 398.52px;
  min-height: 430.78px;
}

@media screen and (max-width: 992px) {
  .homepageCompTwo-flexd {
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 50px;
    padding-top: 0;
  }

  .homepageCompTwo-LeftOnete {
    font-weight: 800;
    font-size: 30px;
  }
  .homepageCompTwo-flexLeft {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 0px;
    width: 100%;
  }

  .homepageCompTwo-LeftOneFuText {
    margin-top: 30px;
    text-align: center;
  }
  .homepageCompTwo-RightImg {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  .homepageCompTwo-RightImg img {
    max-width: 339.99px;
    min-height: 364.01px;
  }

  .homepageCompTwo-LeftOneImg {
    /* margin-right: -50px; */
    margin-right: auto;
    margin-left: auto;
    margin-top: -20px;
  }

  .homepageCompTwo-LeftOneImg img {
    width: 169px;
    height: 98.44px;
  }

  .homepageCompTwo-LeftOneBtn {
    cursor: pointer;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 14px 53px; */

    /* max-width: 340px;
  min-height: 70px; */

    padding: 20px 55px;
    background: #36a6a4;
    border-radius: 3px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;

    color: #ffffff;
  }
}

/* .homepageCompTwo-LeftOneImg img {

} */
