@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

body {
  font-family: "Roboto", "Poppins", sans-serif;

  margin: 0;
  padding: 0;
}

.homepageCompThree-flexdd {
  display: flex;

  align-items: center;
  /* margin-right: auto; */
  /* margin-left: auto; */
  justify-content: center;
  width: 100%;

  /* width: 1020px; */
  margin-top: 200px;
}

.homepageCompThree-flexFirst {
  max-width: 500px;
  /* margin-right: 0; */
  min-height: 365px;
}
/* .homepageCompThree-flexFirst img {
  width: 100%;
  height: 100%;
} */
.homepageCompThree-flexTwo {
  /* margin-left: 0; */
  background: #f5f5f5;
  backdrop-filter: blur(20px);
  max-width: 600px;
  min-height: 365px;
}
.homepageCompThree-flexTwotext {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 50px;
  width: 80%;
  word-wrap: break-word;
  margin-left: 40px;
  margin-top: 70px;
  letter-spacing: 0.01em;

  /* Text color */

  color: #333333;
}
/* .homepageCompThree-flexTwotext > .homepageCompThree-flexTwotextSub {
  display: flex;
  align-items: flex-end;
} */
.homepageCompThree-flexTwotextSub {
  display: flex;
  align-items: center;
  /* justify-content: flex-end; */
  margin-top: auto;
  /* margin-bottom: 0; */
  justify-content: center;
  width: 100%;
  /* margin-top: 30px; */
  min-height: 105px;

  background: #36a6a4;
  backdrop-filter: blur(20px);
  position: absolute;
  bottom: 0;
}

.homepageCompThree-TwotextSubone {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 37px;

  background: #ffffff;
  border-radius: 30px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 27px;

  color: #333333;
  margin-right: 5px;
}

.homepageCompThree-TwotextSubtwo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 37px;

  background: #ffffff;
  border-radius: 30px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 27px;

  color: #333333;
  margin-right: 5px;
}
.homepageCompThree-TwotextSubthree {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 37px;

  background: #ffffff;
  border-radius: 30px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 27px;

  color: #333333;
  margin-right: 5px;
}

@media screen and (max-width: 992px) {
  body {
    background: #ffffff;
    overflow-x: hidden;
    /* width: 100%; */
  }

  .homepageCompThree-flexdd {
    flex-direction: column-reverse;
    margin-right: 0;
    margin-left: 0;
    margin-top: 50px;
  }

  .homepageCompThree-flexFirst {
    /* width: 100%; */
    margin-right: 20px;
    margin-left: 20px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .homepageCompThree-flexFirst img {
    width: 340px;
    height: 413px;
  }
  .homepageCompThree-flexTwotext {
    text-align: center;
    font-size: 30px;
    /* margin-bottom: 100px; */
    /* background: green;
    display: block;
    position: relative; */
  }

  .homepageCompThree-flexTwotextSub {
    display: block;
    /* justify-content: center; */
  }

  .homepageCompThree-TwotextSubone {
    width: 190px;
    height: 37px;
    /* padding: 5px 10px; */

    line-height: 0px;

    margin-right: 0px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  .homepageCompThree-TwotextSubtwo {
    width: 266px;
    height: 37px;
    /* padding: 5px 10px; */

    line-height: 0px;

    margin-right: 0px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  .homepageCompThree-TwotextSubthree {
    width: 149px;
    height: 37px;
    /* padding: 5px 10px; */

    line-height: 0px;

    margin-right: 0px;
    margin-top: 10px;
    margin-bottom: 10px;

    margin-left: auto;
    margin-right: auto;
  }
  .homepageCompThree-flexTwotext {
    margin-top: 5px;
  }

  .homepageCompThree-flexTwo {
    width: 100%;
  }
}

@media screen and (max-width: 420px) {
  .homepageCompThree-flexTwotext {
    text-align: center;
    font-size: 23px;
    /* margin-bottom: 100px; */
    /* background: green;
    display: block;
    position: relative; */
    line-height: 40px;
  }
}
