@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

body {
  font-family: "Roboto", "Poppins", sans-serif;

  background: #fcfcfc;
  margin: 0;
  padding: 0;
}

.bodyPos {
  position: relative;
  padding-bottom: 128px;
}
/* .hhhhhhh {
  margin-top: calc(100%-75px);
} */
.homePage-contFam {
  padding-top: 125px;
  width: 100%;
  /* height: 706px; */
  padding-bottom: 70px;

  background: rgba(0, 16, 16, 0.98);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  /* align-items: flex-end; */
  /* align-self: flex-start; */
  justify-content: flex-start;
}
.homePage-contFamLeft {
  margin-left: 100px;
  width: 50%;
  z-index: 1;
}

.homePage-contFamRight {
  margin-right: 100px;
  position: absolute;
  right: 10px;

  margin-top: auto;
  margin-bottom: auto;
  max-width: 650px;
}

.homePage-contFamRight img {
  width: 100%;
  height: 100%;
}
.homePage-contFamLeftF {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 62px;
  color: #ffffff;
  margin-bottom: 50px;
  max-width: 550px;
  word-wrap: break-word;

  letter-spacing: -0.001em;
}
.homePage-contFamLeftS {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
  /* or 188% */

  display: flex;
  align-items: center;
  letter-spacing: 0.015em;

  color: #ffffff;
  max-width: 450px;
  word-wrap: break-word;
  margin-bottom: 50px;
}

.homePage-contFamLeftTBtn {
  display: flex;

  justify-content: center;
  align-items: center;
  /* padding: 10px 25px; */

  max-width: 288px;
  min-height: 84px;

  /* White */

  background: #ffffff;
  font-family: "Poppins";
  font-style: normal;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;

  color: #333333;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 150px;
  transition: 0.5s ease-in-out 0s;
}

.homePage-contFamLeftTBtn:hover {
  background: #36a6a4;
  color: #ffffff;
}

.homePage-contFamLeftFCopy {
  font-family: "Poppins";
  font-style: normal;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;

  color: #ffffff;
}

.homePage-cont-marginFill {
  background: #ffffff;
  margin-left: 100px;
  margin-right: 100px;
  height: 100%;
}

.clclcll {
  margin-left: auto;
  margin-right: auto;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

@media screen and (max-width: 1100px) {
  .homePage-contFamRight img {
    width: 565px;
    height: 100%;
  }

  .homePage-contFamLeftF {
    font-weight: 700;
    font-size: 35px;
  }

  .homePage-contFamLeft {
    padding-top: 50px;
  }
}

@media screen and (max-width: 1020px) {
  .homePage-contFamRight img {
    width: 465px;
    height: 100%;
  }

  .homePage-contFamLeftF {
    font-weight: 700;
    font-size: 30px;
  }

  .homePage-contFamLeft {
    padding-top: 50px;
  }
}
@media screen and (max-width: 992px) {
  body {
    background: #ffffff;
    overflow-x: hidden;
    /* width: 100%; */
  }

  .homePage-contFamLeft {
    padding-left: 0px;
    padding-right: 0px;
    width: 90%;
    margin-left: 30px;
    margin-right: 30px;
    text-align: center;
    flex-direction: column;

    /* align-items: center; */
  }
  .homePage-contFamLeftF {
    font-weight: 600;
    font-size: 42px;
    margin-left: auto;
    margin-right: auto;
    /* line-height: 42px; */
  }
  .homePage-contFamLeftS {
    font-weight: 200;
    font-size: 16px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .homePage-contFamLeftTBtn {
    margin-left: auto;
    margin-right: auto;

    justify-content: center;
    align-items: center;
    max-width: 230px;
    min-height: 60px;

    /* padding: 10px 100px; */

    /* padding: 14px 53px; */

    font-size: 20px;

    margin-bottom: 10px;
  }

  .homePage-contFamRight {
    margin-right: 10px;

    position: relative;
    right: none;

    margin-top: none;
    margin-bottom: none;
  }
  .homePage-contFamRight img {
    display: block;
    /* width: 638px; */
    /* height: 669px; */
    max-width: 643px;
    height: 643px;
  }

  .homePage-contFam {
    flex-direction: column;
    padding-bottom: 0px;
  }

  .homePage-cont-marginFill {
    background: #ffffff;
    margin-left: 0px;
    margin-right: 0px;
    height: 100%;
  }
}

@media screen and (max-width: 578px) {
  .homePage-contFamRight img {
    max-width: 443px;
    height: 443px;
  }
}

@media screen and (max-width: 578px) {
  .homePage-contFamLeftF {
    font-weight: 700;
    font-size: 35px;
    margin-left: auto;
    margin-right: auto;
    line-height: 42px;
  }
}

@media screen and (max-width: 493px) {
  .homePage-contFamLeftF {
    font-weight: 700;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 420px) {
  .homePage-contFamLeftF {
    font-weight: 700;
    font-size: 25px;
    margin-left: auto;
    margin-right: auto;
  }
  .homePage-contFamLeftTBtn {
    margin-left: auto;
    margin-right: auto;

    justify-content: center;
    align-items: center;

    padding: 2px 5px;

    /* padding: 14px 53px; */

    font-size: 16px;

    /* margin-bottom: 50px; */
  }
}
@media screen and (max-width: 390px) {
  .homePage-contFamRight img {
    max-width: 343px;
    height: 343px;
  }
}
@media screen and (max-width: 349px) {
  .homePage-contFamLeftF {
    font-weight: 700;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}
