@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

body {
  font-family: "Roboto", "Poppins", sans-serif;

  background: #fcfcfc;
  margin: 0;
  padding: 0;
}
.bodyPos {
  position: relative;
  padding-bottom: 128px;
}
.pricingPage-top {
  padding-top: 70px;
  margin-left: 100px;
  margin-right: 100px;
  /* width: 100%; */
  background: #ffffff;
}

.pricingPage-topSelFam {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
}

.pricingPage-topt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  letter-spacing: 0.01em;
  color: #333333;
}

.pricingPage-toptt {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  letter-spacing: 0.04em;

  color: #333333;

  opacity: 0.7;
  margin-top: 5px;
}

.pricingComp-famFlexx {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: #ffffff;
}

@media screen and (max-width: 992px) {
  body {
    background: #ffffff;
    overflow-x: hidden;
    /* width: 100%; */
  }

  .pricingPage-top {
    padding-top: 50px;

    margin-left: 0px;
    margin-right: 0px;
  }

  .pricingPage-topt {
    font-size: 30px;
    text-align: center;
  }

  .pricingPage-toptt {
    font-size: 20px;
    text-align: center;
    width: 65%;
    word-wrap: break-word;
    margin-bottom: 50px;
  }
}

/* @media screen and (max-width: 420px) {
  .homePage-contFamLeftF {
    font-weight: 600;
    font-size: 37px;
    margin-left: auto;
    margin-right: auto;
  }
} */
