.authorizer-Gen {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  /* margin: auto; */
  /* width: 100%; */
  height: 100vh;
  padding-top: 200px;
}

@media screen and (max-width: 992px) {
  .authorizer-Gen {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    /* margin: auto; */
    /* width: 100%; */
    height: 100vh;
    padding-top: 200px;
  }
}

@media screen and (max-width: 600px) {
  .authorizer-Gen {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    /* margin: auto; */
    /* width: 100%; */
    height: 100vh;
    padding-top: 200px;
  }
}

@media screen and (max-width: 400px) {
  .authorizer-Gen {
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
    /* margin: auto; */
    /* width: 100%; */
    height: 100vh;
    padding-top: 200px;
  }
}
