@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

body {
  font-family: "Roboto", "Poppins", sans-serif;

  margin: 0;
  padding: 0;
}

.teampage-compOnep {
  max-width: 252px;

  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;

  background: #476665;
  backdrop-filter: blur(40px);

  border-radius: 40px;
  min-height: 316px;
  margin-right: 5px;
  /* margin-top: 20px; */
  margin-top: 20px;
}

.teampage-compOnepp {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  /* or 125% */

  color: #ffffff;
  width: 80%;
  word-wrap: break-word;
  margin-top: 20px;
  height: 20%;
}

.teampage-compOneppp {
  margin-top: 20px;
  margin-bottom: 30px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;

  letter-spacing: 0.01em;

  color: #ffffff;
  width: 80%;
  word-wrap: break-word;
  height: 80%;
}

.teampage-compOneFam {
  /* margin-top: 90px; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background: #ffffff;
}

@media screen and (min-width: 1600px) {
}
