.pricingCompWhatsBack {
  padding-top: 10px;
  margin-left: -100px;
  margin-right: -100px;
  padding-bottom: 10px;
  margin-top: 50px;
  margin-bottom: 30px;
}

.pricingWhatsGenn {
  margin-left: 100px;
  margin-right: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
}

.pricingWhatsGenn-Texttx {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  color: #ffffff;
  margin-right: 40px;
}

.pricingWhatsGenn-section-V {
  border: 1px solid #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  transition: 0.5s ease-in-out 0s;
}

.pricingWhatsGenn-section-V:hover {
  background: rgba(255, 255, 255, 0.1);
}

.pricingWhatsGenn-IMG {
  margin-right: 10px;
}
.pricingWhatsGenn-IMG img {
  width: 20px;
  height: 20px;
}

.pricingWhatsGenn-WhatsMessa {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  color: #ffffff;
}

@media screen and (max-width: 992px) {
  .pricingCompWhatsBack {
    padding-top: 20px;
    margin-left: 0px;
    margin-right: 0px;
    padding-bottom: 20px;
    margin-top: 50px;
    margin-bottom: 30px;
  }

  .pricingWhatsGenn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .pricingWhatsGenn-Texttx {
    text-align: center;
    margin-bottom: 20px;
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
  }
  .pricingWhatsGenn-section-V {
    justify-content: center;
    width: 300px;
  }

  /* .pricingWhatsGenn-section-V {
    margin-top: 10px;
    margin-bottom: 20px;
  } */
}
