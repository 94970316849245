@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

body {
  font-family: "Roboto", "Poppins", sans-serif;

  margin: 0;
  padding: 0;
}

.teamCompthree-famGen {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 150px;
}

.teamCompthree-flexleft {
  width: 50%;
  /* margin-left: 20px; */
  /* margin-right: 10px; */
  display: flex;
  /* justify-content: center; */
  justify-content: flex-end;
  align-items: center;
  margin-right: 20px;
}
.teamCompthree-flexleft img {
  max-width: 450.41px;
  min-height: 459px;
}

.teamCompthree-flexright {
  width: 50%;
  /* margin-right: 20px; */
  display: flex;
  /* justify-content: center; */
  /* justify-content: flex-start; */
  align-items: flex-start;
  flex-direction: column;
}

.teamCompthree-flexRight1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 46px;
  line-height: 72px;
  /* text-align: left; */

  letter-spacing: -0.01em;
  max-width: 500px;
  word-wrap: break-word;

  /* Text color */

  color: #333333;
}

.teamCompthree-flexRight2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;

  letter-spacing: 0.015em;
  max-width: 450px;
  word-wrap: break-word;
  margin-top: 20px;

  /* Text color */

  color: #333333;

  opacity: 0.7;
}

@media screen and (max-width: 992px) {
  body {
    background: #ffffff;
    overflow-x: hidden;
    /* width: 100%; */
  }

  .teamCompthree-famGen {
    /* display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 150px; */

    flex-direction: column;
  }

  .teamCompthree-flexleft {
    width: 100%;
    /* margin-left: 20px; */
    /* margin-right: 10px; */
    /* display: flex; */
    /* justify-content: center; */
    justify-content: center;
    /* margin-left: 40px; */
    margin-right: auto;
    /* align-items: center; */
  }
  .teamCompthree-flexright {
    width: 100%;
    /* margin-right: 20px; */
    /* display: flex; */
    /* justify-content: center; */
    /* justify-content: flex-start; */
    align-items: center;
    /* flex-direction: column; */
  }

  .teamCompthree-flexRight1 {
    margin-top: 0px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    text-align: center;
    line-height: 42px;

    width: 90%;
  }

  .teamCompthree-flexRight2 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;

    text-align: center;

    letter-spacing: 0.015em;
    width: 90%;
  }

  .teamCompthree-flexleft img {
    max-width: 341px;
    min-height: 479px;
  }
}
