@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

body {
  font-family: "Roboto", "Poppins", sans-serif;

  background: #fcfcfc;
  margin: 0;
  padding: 0;
}

.pricingTickCFam-Gen {
  max-width: 339px;
  min-height: 517px;

  background: #f5f5f5;
  padding: 25px 25px;
  margin-top: 50px;
  margin-right: 20px;
  margin-top: 20px;
  /* margin-bottom: 50px; */
}

.pricingTickC-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 52px;
  /* identical to box height, or 145% */

  /* Text color */

  color: #333333;
  margin-top: 5px;
}

.pricingTickC-text-back {
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  align-items: center;
  /* padding: 2px 10px;
   */
  max-width: 85px;
  min-height: 18px;

  background: #fcb816;
  border-radius: 13px;

  font-family: "poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;

  color: #ffffff;
  margin-top: 5px;
}

.pricingTickC-text-w {
  margin-top: 40px;
  margin-bottom: 30px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;

  /* Text color */

  color: #333333;
}

.pricingTickCFam {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.pricingTickC-textImg {
  margin-right: 20px;
}

.pricingTickC-textOpt {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;

  color: #333333;
}

.break-line {
  width: 90%;
  border-bottom: 1.8px dashed #a9a9aa;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pricingTickCPrice {
  display: flex;
  align-items: flex-end;
}
.pricingTickCPrice-amount {
  font-family: "poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 41.6538px;

  color: #333333;
}

.pricingTickC-year {
  font-family: "poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* text-align: end; */
  color: #333333;
  /* margin-top: -20px; */
  margin-bottom: 10px;
}

.pricingTickCPrice-started {
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  align-items: center;

  max-width: 313px;
  min-height: 52px;

  /* primary/deets green */

  background: #36a6a4;
  border-radius: 13px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;

  color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  transition: 0.5s ease-in-out 0s;
}

.pricingTickCPrice-started:hover {
  background: #248381;
}

.pricing-SecondCard {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pricing-SecondCard img {
  width: 328px;
  height: 200px;
}

.pricingTickCTextt {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;

  color: #333333;
  /* margin-bottom: 50px; */
  margin-top: 70px;
  height: 100px;
}

.image-relContTwo {
  position: relative;
  width: 328px;
  height: 250px;
  /* background: #fcb816; */
}

.secondSingleCompImg img {
  width: 328px;
  height: 200px;

  position: absolute;
  opacity: 1;
  /* transition: 0.5s; */
}
