@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

body {
  font-family: "Roboto", "Poppins", sans-serif;

  background: #fcfcfc;
  margin: 0;
  padding: 0;
}

.homepageComOne-firstFlex {
  display: flex;
  align-items: center;
  padding-top: 100px;
  padding-left: 20px;
  padding-right: 20px;
}

.homepageComOne-rightFlex {
  margin-right: 70px;
  max-width: 448px;
  /* min-height: 475px; */
}

.homepageComOne-rightFlex img {
  width: 100%;
  height: 100%;
}

.homepageComOne-leftFlexOne {
  font-family: "Poppins";

  font-style: normal;
  max-width: 500px;
  word-wrap: break-word;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;

  letter-spacing: -0.01em;

  color: #333333;
}

.homepageComOne-leftFlexTwo {
  margin-top: 30px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 30px;
  max-width: 450px;
  word-wrap: break-word;
  letter-spacing: 0.015em;

  /* Text color */

  color: #828282;

  /* opacity: 0.7; */
}

@media screen and (max-width: 992px) {
  body {
    background: #ffffff;
    overflow-x: hidden;
    /* width: 100%; */
  }
  .homepageComOne-firstFlex {
    flex-direction: column-reverse;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 10px;
  }

  .homepageComOne-rightFlex {
    margin-right: 0px;
  }

  .homepageComOne-rightFlex {
    width: 343px;
    height: 365px;
  }

  .homepageComOne-rightFlex img {
    width: 343px;
    height: 365px;
  }

  .homepageComOne-leftFlexOne {
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    line-height: 40px;
  }

  .homepageComOne-leftFlexTwo {
    font-weight: 400;
    /* font-size: 12px; */
    text-align: center;
    margin-bottom: 50px;
    font-size: 16px;
  }
}
