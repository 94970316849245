@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

body {
  font-family: "Roboto", "Poppins", sans-serif;

  background: #fcfcfc;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.bodyPos {
  position: relative;
  padding-bottom: 128px;
}
.teamsPageGenFam-fam {
  padding-top: 75px;
  width: 100%;
  min-height: 756px;

  /* background: #fefefe;
  backdrop-filter: blur(10px); */
  display: flex;
  align-items: center;
  flex-direction: column;
}
.teamsPageGenFam-famtexttd {
  margin-top: 70px;
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 62px;
  line-height: 82px;
  max-width: 1000px;
  word-wrap: break-word;
  letter-spacing: 0.01em;

  /* Text color */

  color: #333333;
}
.teamsPageGenFam-famtgspa {
  display: flex;
  align-items: center;
  flex-direction: column;
  display: inline-flex;
  margin-left: -10px;
}

.teamsPageGenFam-famtgspa img {
  margin-top: -20px;
}
.teamsPageGenFam-famgtgt {
  color: #36a6a4;
  margin-left: 10px;
}

.teamsPageGenFam-secondimg {
  max-width: 1000px;
  position: relative;
  /* align-items: flex-end; */
  /* bottom: 0; */
  /* display: flex;
  flex-direction: column; */
  margin-top: 30px;
  /* align-items: flex-end; */
  /* justify-content: flex-end; */
}

.teamsPageGenFam-secondrel img {
  position: relative;
  z-index: 1;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  margin-right: auto;
  margin-left: auto;
  width: 437px;
  height: 478px;
}
.teamsPageGenFam-secondabs {
  position: absolute;
  right: -100px;
  top: 0;
}

.teamsPageGenFam-famlineMar {
  margin-left: 100px;
  margin-right: 100px;
  background: #ffffff;
  padding-top: 100px;
  /* height: 1000px; */
  /* width: 100%; */
}

.teamsPageComingS img {
  width: 679px;
  height: 113px;

  border-radius: 34px;
  position: absolute;
  bottom: 0;
  z-index: 2;

  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  /* padding-left: 150px;
  padding-right: 150px; */
}
@media screen and (max-width: 992px) {
  body {
    background: #ffffff;
    overflow-x: hidden;
    /* width: 100%; */
  }
  .teamsPageGenFam-fam {
    padding-top: 30px;
    min-height: 0px;
  }
  .teamsPageGenFam-famlineMar {
    margin-left: 0px;
    margin-right: 0px;
  }

  .teamsPageGenFam-secondabs {
    position: absolute;
    right: 200px;
    top: 0;
  }

  .teamsPageGenFam-famtexttd {
    font-size: 30px;
    line-height: 42px;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .teamsPageGenFam-famtgspa img {
    width: 150px;
  }

  .teamsPageGenFam-secondrel img {
    position: relative;
    z-index: 1;
    margin-top: 20px;
    /* display: flex;
  align-items: center;
  justify-content: center; */
    margin-right: auto;
    margin-left: auto;
    width: 321.3px;
    height: 351px;
  }
  /* .comingSoonMobile img {
    width: 299px;
    height: 68px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  } */
}
