@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

body {
  font-family: "Roboto", "Poppins", sans-serif;

  margin: 0;
  padding: 0;
}
.slider-control-centerleft {
  opacity: 0;
}
.slider-control-centerright {
  opacity: 0;
}
.homeCompFour-flexMain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fbfbfb;
  /* background-color: aqua; */
  margin-top: 120px;
}
.homeCompFour-flexLeft {
  margin-left: 50px;
}
.homeCompFour-flexLeftText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 72px;
  letter-spacing: 0.01em;

  /* Text color */

  color: #333333;
}

.homeCompFour-flexLeftTickOne {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.homeCompFour-flexLeftTickTwo {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.homeCompFour-flexLeftTickOneImg {
  max-width: 18.5px;
  min-height: 18.5px;
}

.slideRemobImg img {
  width: 512.95px;
  height: 549.56px;
}

.slideReDeskImg img {
  width: 410.82px;
  height: 453px;
}
.homeCompFour-flexLeftTickOneImg img {
  width: 100%;
  height: 100%;
}

.homeCompFour-flexLeftTickTwoImg {
  max-width: 18.5px;
  min-height: 18.5px;
}

.homeCompFour-flexLeftTickTwoImg img {
  width: 100%;
  height: 100%;
}

.homeCompFour-flexLeftTickFam {
  margin-top: 30px;
}

.homeCompFour-flexLeftTickOneText {
  margin-left: 15px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 36px;
  letter-spacing: 0.015em;

  /* Text color */

  color: #333333;
}

.homeCompFour-flexLeftTickTwoText {
  margin-left: 15px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 36px;
  letter-spacing: 0.015em;

  /* Text color */

  color: #333333;
}

.homeCompFour-OrderCardBtn {
  cursor: pointer;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 14px 53px; */

  max-width: 240px;
  min-height: 74px;

  background: #36a6a4;
  border-radius: 3px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;

  color: #ffffff;

  line-height: 48px;

  letter-spacing: 0.01em;

  transition: 0.5s ease-in-out 0s;
}

.homeCompFour-OrderCardBtn:hover {
  background: #000000;
  color: #ffffff;
}

.homeCompFour-flexRightImgOne {
  min-height: 634px;
  max-width: 329px;
  margin-top: 50px;
  margin-right: auto;
  margin-left: auto;
}

.homeCompFour-flexRightImgOne img {
  width: 100%;
  height: 100%;
}

.homeCompFour-flexRightFam {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.homeCompFour-flexRightImgTwo {
  /* background: #333333; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 20px;
}
.homeCompFour-flexRightImgTwo img {
  margin-right: auto;
  margin-left: auto;
}

@media screen and (max-width: 992px) {
  body {
    background: #ffffff;
    overflow-x: hidden;
    /* width: 100%; */
  }

  .homeCompFour-flexLeftTickFam {
    margin-top: 10px;
  }

  .homeCompFour-flexMain {
    flex-direction: column-reverse;
  }
  .homeCompFour-flexLeft {
    margin-left: 0px;
  }

  .homeCompFour-OrderCardBtn {
    margin-right: auto;
    margin-left: auto;

    max-width: 236px;
    min-height: 55px;

    font-weight: 700;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .homeCompFour-flexRightFam {
    width: 100%;
  }

  .slideRemobImg img {
    width: 100%;
    height: 549.56px;
  }
}

@media screen and (max-width: 420px) {
  .homeCompFour-flexLeftText {
    text-align: center;
    font-size: 32px;
  }

  .homeCompFour-flexLeftTickOneText {
    font-size: 16px;
  }

  .homeCompFour-flexLeftTickTwoText {
    font-size: 16px;
  }
}
