@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

body {
  font-family: "Roboto", "Poppins", sans-serif;

  margin: 0;
  padding: 0;
}

.homepageCompFive-famG {
  margin-top: 120px;
  width: 100%;
  /* height: 835px; */
  position: relative;
}
.homepageCompFive-famGImg {
  max-width: 100%;
  /* min-height: 100%; */

  /* max-width: 1088px; */
  min-height: 500px;
}

.homepageCompFive-famGImg img {
  width: 100%;
  height: 100%;
}

.homepageCompFive-famContt {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  bottom: 30px;
  width: 90%;
  min-height: 369px;
  left: 0;
  border-radius: 80px;
  right: 0;

  margin-left: auto;
  margin-right: auto;

  background: rgba(12, 140, 233, 0.7);
  backdrop-filter: blur(10px);
}

.homepageCompFive-famConttext {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 72px;

  letter-spacing: 0.01em;

  color: #ffffff;
}

.homepageCompFive-famConttextTwo {
  max-width: 60%;
  word-wrap: break-word;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;

  letter-spacing: 0.015em;

  /* White */

  color: #ffffff;

  opacity: 0.7;
  text-align: center;
  margin-top: 5px;
}

.homepageCompFive-famConttextBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 75px;
  /* max-width: 355px;
  min-height: 90px; */

  /* padding: 20px 35px; */

  /* width: 455px;
  height: 104px; */
  background: #ffffff;
  border-radius: 3px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 27px;

  color: #333333;
  cursor: pointer;
  margin-top: 30px;
  transition: 0.5s ease-in-out 0s;
}

.homepageCompFive-famConttextBtn:hover {
  background: #000000;
  color: #ffffff;
}

@media screen and (max-width: 992px) {
  body {
    background: #ffffff;
    overflow-x: hidden;
    /* width: 100%; */
  }

  .homepageCompFive-famConttext {
    font-size: 20px;
    line-height: 40px;
  }

  .homepageCompFive-famContt {
    bottom: 0px;
    width: 100%;
    min-height: 179px;
    border-radius: 0;
  }

  .homepageCompFive-famConttextBtn {
    padding: 15px 45px;

    font-size: 13px;

    margin-top: 10px;
  }
  .homepageCompFive-famConttextTwo {
    max-width: 80%;

    font-size: 15px;
    line-height: 20px;

    margin-top: 0px;
  }
}

@media screen and (max-width: 420px) {
  .homepageCompFive-famConttext {
    font-size: 16px;
    line-height: 40px;
  }

  .homepageCompFive-famConttextTwo {
    max-width: 80%;

    font-size: 13px;
    line-height: 20px;

    margin-top: 0px;
  }
  .homepageCompFive-famContt {
    bottom: 20px;
  }
}

@media screen and (max-width: 395px) {
  .homepageCompFive-famContt {
    bottom: 50px;
  }
}

@media screen and (max-width: 355px) {
  .homepageCompFive-famContt {
    bottom: 80px;
  }
}
