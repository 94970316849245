@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  font-family: "Roboto", sans-serif;

  background: #fcfcfc;
  margin: 0;
  padding: 0;
}
.web-footerFama {
  background: #171923;
  margin-left: 100px;
  margin-right: 100px;
  height: 128px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}

.webfooterPos {
  width: 100%;
  bottom: 0;
  position: absolute;
}

.web-footerFamLogo {
  margin-left: 20px;
}
.web-footerMain {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.web-footerGet {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;

  color: #36a6a4;
}
.web-footerGetLog {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  color: #e9e9e9;
  margin-top: 5px;
  cursor: pointer;
  transition: 0.5s ease-in-out 0s;
}

.web-footerGetLog:hover {
  color: #999999;
}

.web-footerGetCard {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  color: #e9e9e9;
  margin-top: 5px;
  cursor: pointer;
  transition: 0.5s ease-in-out 0s;
}

.web-footerGetCard:hover {
  color: #999999;
}

.web-footerDeets {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;

  color: #36a6a4;
}

.web-footerDeetsIndi {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  color: #e9e9e9;
  margin-top: 5px;
  cursor: pointer;
  transition: 0.5s ease-in-out 0s;
}

.web-footerDeetsTeam {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  color: #e9e9e9;
  margin-top: 5px;
  cursor: pointer;
}
.web-footerflexThree {
  margin-right: 20px;
}
.web-footerSubflexThreess {
  margin-right: 40px;
}
.web-footerflexHelp {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;

  color: #36a6a4;
}
.web-footerSubflexThreessFaq {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  color: #e9e9e9;
  margin-top: 5px;
  cursor: pointer;
}

.web-footerSubflexThreessCont {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  color: #e9e9e9;
  margin-top: 5px;
  cursor: pointer;
}

.web-footerSubflexThreebbbcond {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin-top: 5px;

  color: #e9e9e9;
  cursor: pointer;
}

.web-footerSubflexThreebbbpriva {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  color: #e9e9e9;
  margin-top: 5px;
  cursor: pointer;
}
.web-footerSubflexThree {
  display: flex;
  /* align-items: left; */
  text-align: left;
  /* justify-content: space-between; */
}
.web-footerSubflexCopy {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;

  color: #6b6c72;
}

@media screen and (max-width: 992px) {
  body {
    background: #ffffff;
    overflow-x: hidden;
    /* width: 100%; */
  }

  .web-footerGet {
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
  }

  .web-footerGetLog {
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
  }

  .web-footerGetCard {
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
  }

  .web-footerDeets {
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
  }

  .web-footerDeetsIndi {
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
  }

  .web-footerDeetsTeam {
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
  }

  .web-footerFama {
    margin-left: 0px;
    margin-right: 0px;
  }

  .web-footerMain {
    flex-direction: column;
  }

  .web-footerFama {
    height: 415px;
  }

  .web-footerflexOne {
    margin-top: 10px;
  }
  .web-footerflexTwo {
    margin-top: 30px;
  }

  .web-footerflexThree {
    margin-top: 30px;
  }
  .web-footerMain {
    margin-top: 20px;
  }

  .web-footerflexHelp {
    text-align: center;

    font-size: 16px;
    margin-bottom: 10px;
  }
  .web-footerSubflexThreessFaq {
    font-size: 16px;
  }

  .web-footerSubflexThreessCont {
    font-size: 16px;
  }

  .web-footerSubflexThreebbbcond {
    font-size: 16px;
  }

  .web-footerSubflexThreebbbpriva {
    font-size: 16px;
  }

  .web-footerSubflexCopy {
    font-size: 16px;
  }
  .web-footerflexThree {
    margin-right: 0px;
  }

  .web-footerSubflexThreess {
    margin-right: 100px;
  }
}
