@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

body {
  font-family: "Roboto", "Poppins", sans-serif;

  margin: 0;
  padding: 0;
}

.teamComp-ThreeGenFam {
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.teamComp-ThreeFlex1 {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  width: 50%;
  /* margin-right: 10px; */
  margin-left: 15px;
}
.teamComp-ThreeFlex1t {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 46px;
  line-height: 72px;

  text-align: right;
  letter-spacing: -0.01em;

  color: #333333;
  max-width: 500px;
  word-wrap: break-word;
}

.teamComp-ThreeFlex1tt {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;

  text-align: right;
  letter-spacing: 0.015em;
  width: 500px;
  word-wrap: break-word;

  /* Text color */

  color: #333333;

  opacity: 0.7;
}
.teamComp-ThreeFlex2 {
  width: 50%;
  padding-left: 30px;
}

.teamComp-ThreeFlex2 img {
  width: 447.99px;
  height: 537.65px;
  /* margin-left: 30px; */
}

@media screen and (max-width: 992px) {
  body {
    background: #ffffff;
    overflow-x: hidden;
    /* width: 100%; */
  }
  .teamComp-ThreeGenFam {
    /* margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between; */

    flex-direction: column-reverse;
  }

  .teamComp-ThreeFlex1 {
    /* display: flex; */
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    /* margin-right: 10px; */
    margin-left: 0px;
    text-align: center;
  }

  .teamComp-ThreeFlex1t {
    font-size: 32px;
    line-height: 52px;
    width: 90%;
    text-align: center;
  }

  .teamComp-ThreeFlex1tt {
    width: 90%;
    text-align: center;

    /* Text color */
  }

  .teamComp-ThreeFlex2 {
    margin-top: 10px;
    width: 90%;
    /* padding-left: 30px; */
  }

  .teamComp-ThreeFlex2 img {
    width: 341.99px;
    height: 410px;
    /* margin-left: 30px; */
  }
}
