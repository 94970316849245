@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  font-family: "Roboto", sans-serif;

  background: #fcfcfc;
  margin: 0;
  padding: 0;
}
.fiiii {
  position: fixed;
  width: 100%;
  z-index: 1000000000;
}
.navbar-headingMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  height: 70px;
}
.navbar-headingLogo {
  margin-left: 100px;
  cursor: pointer;
}

.navbar-headingLogo img {
  max-width: 124.84px;
  max-height: 29.59px;
}
.navbar-headingTitle {
  margin-right: 100px;
  display: flex;
  align-items: center;
}
.navbar-headingTitleNone {
  display: none;
}
.navbar-headTitleOne {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;

  color: #333333;
  margin-right: 25px;
  cursor: pointer;
}

.navbar-headTitleLogin {
  width: 176px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: #ffffff;

  border: 1px solid #333333;
  border-radius: 3px;

  font-style: normal;
  font-weight: 300;
  font-size: 14px;

  color: #333333;
  margin-right: 10px;
  box-sizing: border-box;
  cursor: pointer;
}

.navbar-headTitleOrder {
  width: 176px;
  height: 45px;

  background: #36a6a4;
  border-radius: 3px;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;

  color: #ffffff;
  cursor: pointer;
}
.navbar-headingTitle a {
  text-decoration: none;
}
.activeHeader {
  border-bottom: 3px solid #36a6a4;
  border-radius: 3px;
  font-weight: 600;
  padding-bottom: 10px;
  margin-top: 10px;
  font-style: normal;
  font-size: 14px;

  color: #333333;
  margin-right: 25px;
  cursor: pointer;
}

.welcome-userName {
  font-weight: 600;

  font-style: normal;
  font-size: 10px;

  color: #36a6a4;
  text-align: center;
  margin-right: 5px;
  margin-left: 5px;

  width: 100px;
  word-wrap: break-word;
}

.welcome-userNameText {
  font-weight: 600;

  font-style: normal;
  font-size: 13px;
  color: #000;
  margin-top: 2px;
}

@media screen and (max-width: 1030px) {
  .navbar-headingLogo img {
    width: 724.84px;
    height: 20.59px;
  }

  .activeHeader {
    font-size: 12px;
    margin-right: 10px;
  }
}
@media screen and (max-width: 992px) {
  .navbar-headingLogo {
    margin-left: 20px;
  }

  .navbar-headingLogo img {
    width: 82.89px;
    height: 19.65px;
  }
}
