@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

body {
  font-family: "Roboto", "Poppins", sans-serif;

  background: #fcfcfc;
  margin: 0;
  padding: 0;
}

.pricingTickFam-Gen {
  max-width: 339px;
  min-height: 517px;

  background: #f5f5f5;
  padding: 25px 25px;
  margin-top: 50px;
  margin-right: 20px;
  margin-top: 20px;
  /* margin-bottom: 50px; */
}

.pricingTick-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 52px;
  /* identical to box height, or 145% */

  /* Text color */

  color: #333333;
  margin-top: 5px;
}

.pricingTick-text-back {
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  align-items: center;
  /* padding: 2px 10px;
   */
  max-width: 85px;
  min-height: 18px;

  background: #fcb816;
  border-radius: 13px;

  font-family: "poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;

  color: #ffffff;
  margin-top: 5px;
}

.pricingTick-text-w {
  margin-top: 40px;
  margin-bottom: 30px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;

  /* Text color */

  color: #333333;
}

.pricingTickFam {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.pricingTick-textImg {
  margin-right: 20px;
}

.pricingTick-textOpt {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;

  color: #333333;
}

.break-line {
  width: 90%;
  border-bottom: 1.8px dashed #a9a9aa;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pricingTickPrice {
  display: flex;
  align-items: flex-end;
}
.pricingTickPrice-amount {
  font-family: "poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 41.6538px;

  color: #333333;
}

.pricingTick-year {
  font-family: "poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* text-align: end; */
  color: #333333;
  /* margin-top: -20px; */
  margin-bottom: 10px;
}

.pricingTickPrice-started {
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  align-items: center;

  max-width: 313px;
  min-height: 52px;

  /* primary/deets green */

  background: #36a6a4;
  border-radius: 13px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;

  color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  transition: 0.5s ease-in-out 0s;
}
.pricingTickPrice-started:hover {
  background: #248381;
}

.pricing-FirstCard {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pricing-FirstCard img {
  width: 328px;
  height: 200px;
}

.pricingTickTextt {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;

  color: #333333;
  /* margin-bottom: 50px; */
  /* margin-top: 30px; */
  height: 100px;
  margin-top: 70px;
}

.image-relCont {
  position: relative;
  width: 328px;
  height: 200px;
  /* background: #fcb816; */
}

.firstSingleCompImg img {
  width: 328px;
  height: 200px;

  position: absolute;
  /* transition: 0.5s; */
}

.secondSingleCompImg img {
  width: 328px;
  height: 200px;

  position: absolute;
  /* left: 0;
  right: 0;
  top: 0;
  margin: auto; */
  /* bottom: 50px;
  z-index: 9;
  opacity: 0.85; */
}

.thirdSingleCompImg img {
  width: 328px;
  height: 200px;

  position: absolute;
  /* left: 0;
  right: 0;
  top: 0;
  margin: auto; */
  /* bottom: 40px;
  z-index: 8;
  opacity: 0.75; */
}

.fourthSingleCompImg img {
  width: 328px;
  height: 200px;

  position: absolute;
  /* left: 0;
  right: 0;
  top: 0;
  margin: auto; */
  /* bottom: 30px;
  z-index: 7;
  opacity: 0.65; */
}

.fifthSingleCompImg img {
  width: 328px;
  height: 200px;

  position: absolute;
  /* left: 0;
  right: 0;
  top: 0;
  margin: auto; */
  /* bottom: 20px;
  z-index: 6;
  opacity: 0.55; */
}
.sixthSingleCompImg img {
  width: 328px;
  height: 200px;

  position: absolute;
  /* left: 0;
  right: 0;
  top: 0;
  margin: auto; */
  /* bottom: 10px;
  z-index: 5;
  opacity: 0.45; */
}
