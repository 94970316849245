@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

body {
  font-family: "Roboto", "Poppins", sans-serif;

  background: #fcfcfc;
  margin: 0;
  padding: 0;
}
.bodyPos {
  position: relative;
  padding-bottom: 128px;
  height: 100%;
}

.contactPage-famGen {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  /* margin-left: 100px; */
  /* margin-right: 100px; */
}

.contactPage-famFlex1 {
  width: 50%;
}
.contactPage-whatsApp {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 700;
  font-size: 65px;

  text-decoration-line: underline;

  /* PROVIDUS YELLOS */

  color: #fcb816;
}

.contactPage-famFlex1tess {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;

  color: #ffffff;
  width: 70%;
  word-wrap: break-word;
  margin-left: 50px;
}
.contactPage-famFlex2 {
  width: 50%;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
  height: 100%;
  /* margin-right: 100px; */
}

.contactPage-famFlexText {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.contactPage-famFlexText-emailInput {
  width: 70%;
  height: 42px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.contactPage-famFlexText-emailInput input {
  width: 100%;
  height: 100%;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  color: #949494;

  padding-left: 10px;
  padding-right: 10px;
  outline: none;
}

.contactPage-famFlexText-messageInput {
  width: 70.2%;
  height: 390px;

  margin-bottom: 30px;
  margin-top: 20px;
}
.contactPage-famFlexText-messageInput textarea {
  width: 100%;
  height: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  color: #949494;
  padding-left: 10px;
  padding-right: 12px;
  outline: none;
}

.contactPage-famFlexText-sendInput {
  margin-top: 5px;
  width: 350px;
  height: 42px;
  margin-right: -20px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;

  /* White */

  color: #ffffff;
  border: 1px solid #ffffff;
  background: transparent;
  border-radius: 10px;
}

.faqConatSec {
  margin-left: 100px;
  margin-right: 100px;
  background: #ffffff;
}

.contactPageF-famGen {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  min-height: 359px;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0px;
  padding-top: 100px;
  box-sizing: border-box;
}

.contactPageF-famImg {
  width: 50%;
  height: 349px;
  overflow: hidden;
  /* Magic */
  display: flex;
  align-items: center; /* vertical */
  justify-content: center; /* horizontal */
  margin-top: -10px;

  /* background: black; */
}
.contactPageF-famImg img {
  width: 100%;
  /* height: 100%; */
  min-height: 359px;
  margin-left: -5px;
}

.contactPageF-Flex2 {
  max-width: 50%;
  min-height: 359px;
  margin-right: auto;
  margin-left: auto;
}

.contactPageF-whatsTextFam {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  max-width: 1000px;
  word-wrap: break-word;
  margin-left: auto;
  margin-right: auto;
}

.contactPageF-emailText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;

  color: #ffffff;
}

.contactPageF-whatstext {
  margin-left: 8px;
  font-family: "Poppins";
  font-style: italic;
  font-weight: 700;
  font-size: 28px;

  text-decoration-line: underline;

  color: #fcb816;
}

.contactPageF-inputFam {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 700px;
}

.contactPageF-emailInput {
  width: 70%;
  height: 42px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.contactPageF-emailInput input {
  width: 100%;
  height: 100%;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  color: #949494;

  padding-left: 10px;
  padding-right: 10px;
  outline: none;
}
.contactPageF-messageInput {
  width: 70.2%;
  height: 135px;

  margin-bottom: 10px;
}

.contactPageF-messageInput textarea {
  width: 100%;
  height: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #949494;
  padding-left: 10px;
  padding-right: 12px;
  outline: none;
}

.contactPageF-sendInput {
  margin-top: 5px;
  /* max-width: 350px;
  min-height: 42px; */
  padding: 10px 75px;
  margin-right: -20px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;

  color: #ffffff;
  border: 1px solid #ffffff;
  background: transparent;
  border-radius: 10px;
}

@media screen and (min-width: 1809px) {
  .faqConatSec {
    width: 2500px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 992px) {
  body {
    background: #ffffff;
    overflow-x: hidden;
    /* width: 100%; */
  }

  .faqConatSec {
    margin-left: 0px;
    margin-right: 0px;
    /* background: #ffffff; */
  }

  .contactPage-famGen {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .contactPageF-famGen {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    min-height: 359px;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0px;
    box-sizing: border-box;
    padding-top: 100px;
  }

  .contactPageF-famImg {
    width: 100%;
    height: 359px;
    margin-top: 0px;
  }

  .contactPageF-famImg img {
    margin-top: -120px;
    width: 100%;
    height: 100%;
  }

  .contactPageF-emailText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;

    color: #ffffff;
  }

  .contactPageF-whatstext {
    margin-left: 0px;
    font-family: "Poppins";
    font-style: italic;
    font-weight: 700;
    font-size: 28px;
    display: inline;
    text-decoration-line: underline;

    color: #fcb816;
  }
  .contactPageF-whatsTextFam {
    /* display: flex;
  align-items: center;
  justify-content: center; */
    margin-top: -20px;
    width: 75%;
    text-align: center;
  }

  .contactPageF-inputFam {
    width: 100%;
  }

  .contactPageF-emailInput {
    width: 90%;
    /* height: 42px;
  margin-top: 20px;
  margin-bottom: 10px; */
    /* margin-left: auto;
    margin-right: auto; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contactPageF-messageInput {
    width: 90%;
    /* height: 135px;

  margin-bottom: 10px; */
    /* margin-left: auto;
    margin-right: auto; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contactPageF-Flex2 {
    max-width: 100%;
    /* min-height: 359px;
  margin-right: auto;
  margin-left: auto; */
  }

  .contactPageF-sendInput {
    margin-top: 5px;
    /* max-width: 350px;
  min-height: 42px; */
    padding: 10px 115px;
    margin-right: 0px;
  }
}
